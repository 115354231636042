@import url("//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/leaflet.css");
@import url("//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.3/leaflet.draw.css");
/*@import url("//unpkg.com/maplibre-gl@1.14.0/dist/maplibre-gl.css");*/


@import './teste.css';

/*login*/
.login-page {
  height: 100vh;
  width: auto;
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
}

.login-img {
  background-image: url('/assets/covers/4.jpg');
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.login-div {
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  min-width: 18rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  max-width: 20rem;
  min-width: 18rem;
}

.login-container {
  margin-left: auto;
  margin-right: auto;
}

.login-div h2 {
  font-size: 2.125rem;
  margin-bottom: 1.5rem;

  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}

.login-div .lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.login-div h2,
.login-div .lead {
  margin-bottom: 1.5rem;
  text-align: center;
}

.login-div a {
  text-align: center;
}

.login-button {
  height: 2rem;
  width: 100%;
  text-align: center;
}

.bitMoreSpace {
  position: relative;
  margin-top: 1rem;
}

/*******/
.rowMap {
  position: relative;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  grid-template-columns: 15rem auto;
  grid-gap: 1rem;
}

.rowMapC {
  position: relative;
  display: grid;
  grid-template-columns: 15rem auto;
  grid-gap: 1rem;
}

.rowMapCO {
  position: relative;
  display: grid;
  grid-template-columns: 25rem auto;
  grid-gap: 1rem;
}

.rowForm {
  padding: 2rem;
  min-height: calc(100vh - 5.2rem);
}

.divider {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.divContent {
  padding: 1rem;
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
}

.divContentList {
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
}

.divContentItem {
  margin: 1rem;
  padding: 1rem;
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
  min-width: fit-content;
  max-height: fit-content;
}

.cursorP {
  cursor: pointer;
  z-index: 5000 !important;
}

.resumos {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
}

.resumoTracker {
  margin-bottom: 15px;
  padding: 15px 15px 20px 15px;
  width: 350px;
  height: 310px;
  margin-right: 10px;
}

.resumoTracker h1 {
  padding-left: 5px;
  text-align: center;
  background: rgb(255, 133, 27);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
}

.tabelaResumo {
  display: grid;
}

.tempoResumo {
  display: inline-block;
  border-bottom: 1px solid #aaa;
}

.tempoResumo span {
  float: right;
  margin-right: 2px;
}

.leaflet-container {
  height: 100%;
  min-width: 20rem;
  border: 1px solid rgb(233, 233, 233);
}

.leaflet-edit-marker-selected {
  border: 4px dashed orange !important;
}

.sr-only {
  display: none;
}

.gridDoble {
  grid-column: 1 / 3;
}

.leaflet-div-icon {
  border: 2px solid #666 !important;
  border-radius: 35px !important;
  height: 15px !important;
  width: 15px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-bandana {
  background-color: orange;
  border: 4px solid rgb(237, 231, 231) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-liga {
  background-color: DarkCyan;
  border: 4px solid rgb(7, 151, 26) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-desliga {
  background-color: DarkCyan;
  border: 4px solid rgb(228, 34, 34) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-voo {
  background-color: DarkCyan;
  border: 4px solid rgb(28, 24, 236) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-init {
  background-color: DarkCyan;
  border: 4px solid rgb(226, 223, 17) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-stop {
  background-color: orange;
  border: 4px solid rgb(204, 107, 17) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.containerTitle {
  /*height: 3rem;*/
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.overall {
  z-index: 999;
}

.date-pick {
  margin-right: 50px;
  margin-left: 10px;
}

.containerTop {
  padding-bottom: .5rem;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.leftTop {
  padding-left: 1rem;
  padding-top: .3rem;
  flex: 1 auto 1;
}

.centerTop {
  flex: 1 1 auto;
}

.rightTop {
  text-align: right;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: .3rem;
  flex: 1 1 auto;
}


.buttonS {
  padding: 0rem;
}

.buttonMenu {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  background-color: white !important;
}

.buttonMenuWeather {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  background-color: rgb(250, 125, 52) !important;
}

.buttonMenuHL {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  background-color: #95f098 !important;
}

.buttonMenuAL {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  background-color: #ffd754 !important;
}

.buttonMenuAF {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  color: white !important;
  background-color: #e0bc07 !important;
}

.buttonMenuOP {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  color: white !important;
  background-color: #3a8bc9 !important;
}

.buttonMenuPS {
  width: 1.2rem !important;
  margin-right: .2rem !important;
}


.buttonMenuS {
  margin-left: 3rem;
}

.buttonMenuSmall {
  display: none !important;
}

.zoomB {
  position: absolute;
  z-index: 401;
  width: 3.7rem;
  padding: 1rem;
  top: 0;
}

.mapS {
  padding-top: 2rem;
  padding-bottom: .5rem;
}

.iconMap {
  line-height: 2rem;
  width: 2rem !important;
  height: 2rem !important;
}

.iconMapF {
  line-height: 1rem;
  margin-left: -.3rem;
  margin-top: 0rem;
  color: black;
  width: 2rem !important;
  height: 2rem !important;
}

.iconMapFO {
  line-height: 2rem;
  margin-left: -0.3rem;
  margin-top: 0rem;
  color: black;
  width: 2rem !important;
  height: 2rem !important;
}

.iconMapP {
  margin-top: .2rem;
  line-height: 1.8rem;
  width: 1.3rem !important;
  height: 1.3rem !important;
  color: black;
}

.iconArrowUp {
  margin-top: .3rem;
  line-height: 1.8rem;
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.iconMapS {
  line-height: 2rem;
  width: 2rem !important;
  height: 2rem !important;
  margin-top: -.4rem;
}

.div-list-track {
  height: calc(100% - 17rem);
}

.div-list-track-sumary {
  height: calc(100% - 6rem);
}

.div-listFloat {
  margin-left: 18.5rem;
}

.div-listFloatC {
  margin-left: 17.5rem;
}

.floatW {
  position: absolute;
  top: 1rem;
  text-align: center;
}

.divS {
  padding: 1rem;
  z-index: 997;
  right: 1rem;
  box-shadow: 2px 2px 5px gray;
}

.floatDiv {
  position: absolute;
  top: 1rem;
  margin-left: 4.5rem;
  /* margin-left: -6.5rem; */
  /* left: 50%; */
  z-index: 500;
  text-align: center;
}

.divRelative {
  position: relative;
}

.floatDivItens {
  width: 17.5rem;
  z-index: 1002;
}

.divMP {
  width: 24rem;
  padding: 1rem;
  z-index: 501;
  right: 5rem;
  box-shadow: 2px 2px 5px gray;
}

.divI {
  left: 14rem;
  margin-left: -8rem;
  width: 24rem;
  padding: 1rem;
  z-index: 502;
  box-shadow: 2px 2px 5px gray;
}

.divIA {
  left: 14rem;
  margin-left: -8rem;
  width: 15rem;
  padding: 1rem;
  z-index: 502;
  box-shadow: 2px 2px 5px gray;
}

.formItens {
  display: grid;
  justify-items: center;
  grid-template-columns: auto auto;
}

.divForm {
  margin-top: .2rem;
}

.textI {
  width: 8rem;
  margin-left: .5rem;
}

.SelItem {
  border-left: 6px solid #666 !important;
  background-color: #f0f0f0 !important;
}

.SelItemFull {
  border-left: 6px solid #666 !important;
  border-right: 6px solid #666 !important;
  background-color: #f0f0f0 !important;
}

.notTooBig {
  width: 100%;
  max-width: 40rem;
}

.notTooBig2 {
  width: 100%;
  max-width: 50rem;
}

.notTooClose {
  margin-right: 8px !important;
  width: 11rem;
}

.rel {
  padding: 1rem !important;
  padding-bottom: .2rem !important;
}

.rel-top {
  padding: 1rem !important;
  padding-bottom: .2rem !important;
}

.maxH {
  min-height: calc(100vh - 9.4rem);
  width: 100%;
}

.maxHT {
  min-height: calc(100vh - 14.8rem);
  width: 100%;
}

.maxHG {
  min-height: calc(100vh - 15.8rem);
  width: 100%;
}

.maxVH {
  height: calc(100vh - 15.8rem);
  max-height: calc(100vh - 15.8rem);
  width: 100%;
}

.gridEst {
  display: grid;
  grid-template-rows: min-content auto;
  gap: 1rem;
}

.backStage {
  position: absolute;
  background-color: #f5f5f5;
}

.gridFiltro {
  display: grid;
  grid-template-rows: .5rem 0.3rem;
  grid-template-columns: 11rem 0rem;
}

.login-logo {
  background-image: url('/assets/covers/logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 150px;
  flex: 0 0 100%;
  max-width: 100%;
}

.menu-logo {
  background-image: url('/assets/covers/logo_header.png');
  background-size: 125px 48px;
  width: 125px;
  height: 48px;
}

.div-listFloatP {
  margin-left: 0;
}

@media screen and (max-width: 870px) {
  .rowMap {
    padding: 2rem;
    display: grid;
    grid-template-columns: auto;
  }

  .rowMapC {
    display: grid;
    grid-template-columns: auto;
  }

  .rowMapCO {
    display: block;
  }

  .div-list {
    z-index: 1001;
    position: absolute;
    width: 15rem;
    left: -17.5rem;
    top: 8.1rem;
    height: 75%;
    box-shadow: 2px 2px 5px gray;
    -webkit-transition: all .2s linear;
    /* For Safari 3.1 to 6.0 */
    transition: all .2s linear;
  }

  .div-list_p {
    z-index: 1001;
    position: absolute;
    width: 25rem;
    left: -28rem;
    top: 4.1rem;
    height: calc(100% - 4rem);
    box-shadow: 2px 2px 5px gray;
    -webkit-transition: all .2s linear;
    /* For Safari 3.1 to 6.0 */
    transition: all .2s linear;
  }

  .div-listFloatP {
    margin-left: 28.5rem;
  }

  .leftTop {
    padding-left: 0rem;
  }

  .rightTop {
    padding-right: 0rem;
  }

  .buttonMenuSmall {
    margin-right: .3rem !important;
    display: inline !important;
  }

  .gridEst {
    grid-template-rows: min-content calc(100vh - 16rem);
  }
}

@media screen and (min-width: 871px) {
  .div-listFloat {
    margin-left: 0;
  }

  .div-listFloatC,
  .div-listFloatJ {
    margin-top: 1rem;
    margin-left: 0;
  }

  .list-margin {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .rowMap {
    padding-right: .4rem;
    padding-left: .4rem;
  }

  .rowForm {
    padding: .4rem;
  }

  .gridEst {
    grid-template-rows: min-content auto;
    gap: 0.4rem;
  }

  .maxH {
    min-height: calc(100vh - 5rem);
  }

  .maxVH {
    height: calc(100vh - 12.8rem);
    max-height: calc(100vh - 12.8rem);
  }
}

@media screen and (max-width: 960px) {

  .login-img,
  .login-logo {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .login-div {
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    flex: 0 0 100%;
  }
}

.monitoringMarkerIconBlue {
  background-color: rgb(0, 162, 255) !important;
  border: solid 2px rgb(255, 255, 255) !important;
  border-radius: 35px !important;
  height: 15px !important;
  width: 15px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.monitoringLineBlue {
  stroke: rgb(0, 162, 255);
}

.leaflet-div-icon2 {
  background: none !important;
}

.leaflet-hide-icon2 {
  border: none !important;
}