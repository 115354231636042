@import url(//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/leaflet.css);
@import url(//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.3/leaflet.draw.css);
/* AIRCRAFT CONFIGURATION */

.bodyCheckbox {
    margin: 0.25rem;
}

.bodyCheckboxAtomizer {
    margin: 0.5rem 3.5rem;
}

.bodyCheckboxRightWing {
    margin: 0.25rem;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}

.bodyCheckboxAtomizerRightWing {
    margin: 0.5rem 3.5rem;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}

.customCheckbox {
    position: relative;
    cursor: pointer;
}

.customCheckbox input[type="checkbox"] {
    display: none;
}

.customInputCheckbox {
    display: none;
}

.customCheckbox .checkboxNumber {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    color: black;
    background-color: #fff;
}

.customCheckbox input[type="checkbox"]:checked+.checkboxNumber {
    background-color: #3f51b5;
    border: 2px solid #3f51b5;
    color: #fff;
}

.ContainerLeftWing {
    display: flex;
    margin-left: 7.75rem;
}

.ContainerRightWing {
    display: flex;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
    justify-content: flex-start;
    margin-right: 8rem;
}

.ContainerPlaneCenterRight {
    display: flex;
    margin-left: -20rem;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
    width: 40rem;
}

.ContainerPlaneCenterLeft {
    display: flex;
    margin-left: 20rem;
}

.bodyContainerImg {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.titlePositionItem {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.54);
}

.planeWingImg {
    max-width: 96rem;
    min-width: 50rem;
    width: auto;
    height: 24rem;
}

.planeCenterImg {
    width: 24rem;
    height: 24rem;
    margin-left: 8rem;
}

.containerInputPositionAtomizer {
    padding-top: 1rem;
}

.inputPositionAtomizer {
    width: 100%;
    padding-top: 1rem;
}

.alignCounterItems {
    text-align: left;
}

.inputCounterItems {
    width: 2rem;
}

.buttonItemContainer {
    width: 100%;
    padding-top: 1rem;
}

.titleItem {
    color: black;
    color: rgba(0, 0, 0, 0.54);
}

.buttonItemBody {
    margin-top: 3rem;
    text-align: start;
}

.containerTypeEquipment {
    margin: 1rem 0;
}

.countItemsSpan {
    display: flex;
    font-size: 1.25rem;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
}

/* CALIBRATION */

.containerButtonDrop {
    width: 100%;
    padding-top: 1rem;
    text-align: start;
}

.containerCalibrationTableButton {
    display: flex;
    justify-content: end;
    margin: 1rem;
}

.selectDropType {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 1px solid black;
    cursor: pointer;
    align-self: flex-end;
    padding: 0.5rem 0;
    width: 100%;
    border-radius: 0.25rem 0.25rem 0 0;
}

.selectDropType:hover {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 2px solid black;
    cursor: pointer;
}

.rulerContainer {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    margin-left: 3.75rem;
}

.rulerSegment {
    height: 1px;
    background-color: black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rulerMark {
    position: absolute;
    left: 0;
    top: -3px;
    bottom: -3px;
    width: 1px;
    background-color: black;
}

.rulerDistance {
    position: absolute;
    top: -1.25rem;
    font-size: 0.75rem;
}

.rulerContainer.rulerRight {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
    margin-right: 4rem;
    margin-left: 0;
}

.rulerDistance.invertedText {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}

.rulerDistanceRight {
    position: absolute;
    top: -1.25rem;
    font-size: 0.75rem;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}
.tabs {
    position: relative;
    display: flex;
    border-bottom: 2px solid #ccc;
    overflow: hidden;
}

.tabs button {
    flex: 1 1;
    position: relative;
    padding: 10px 20px;
    border: none;
    background: white;
    cursor: pointer;
    transition: background 0.3s;
}

.tabs button::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #007bff;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.tabs button.active {
    color: #007bff;
}

.tabs button.active::before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
}

.tabs button:hover {
    background: #f5f5f5;
}
.itemBoxCalendarBase {
    position: relative;
    height: 1.4rem;
    border-radius: 3px;
}

.itemBoxCalendar {
    background-color: #3a8bc9;
    height: 1.4rem;
}

.itemBoxCalendarRightEnd {
    background-color: #3a8bc9;
    height: 1.4rem;
}

.itemBoxCalendarRightEnd:after {
    position: absolute;
    right: -.5rem;
    top: 0;
    content: '';
    border-top: .7rem solid transparent;
    border-bottom: .7rem solid transparent;
    border-left: .7rem solid #3a8bc9;
    border-radius: 3px;
    height: 1.4rem;

}

.itemBoxCalendarLeftEnd {
    background-color: #3a8bc9;
    height: 1.4rem;
}

.itemBoxCalendarLeftEnd:before {
    position: absolute;
    left: -.5rem;
    top: 0;
    content: '';
    border-top: .7rem solid transparent;
    border-bottom: .7rem solid transparent;
    border-right: .7rem solid #3a8bc9;
    border-radius: 3px;
    height: 1.4rem;
}

.itemBoxCalendarRight {
    background-color: #3a8bc9;
    height: 1.4rem;
}

.itemBoxCalendarRight:after {
    position: absolute;
    left: calc(100% - .5rem);
    top: 0;
    content: '';
    border-top: .7rem solid #3a8bc9;
    border-bottom: .7rem solid #3a8bc9;
    border-left: 2rem solid #3a8bc9;
    border-radius: 3px;
    height: 1.4rem;
}

/**/
.itemBoxCalendar-po {
    background-color: #6ec2e6;
    height: 1.4rem;
}

.itemBoxCalendarRightEnd-po {
    background-color: #6ec2e6;
    height: 1.4rem;
}

.itemBoxCalendarRightEnd-po:after {
    position: absolute;
    right: -.5rem;
    top: 0;
    content: '';
    border-top: .7rem solid transparent;
    border-bottom: .7rem solid transparent;
    border-left: .7rem solid #6ec2e6;
    border-radius: 3px;
    height: 1.4rem;
}

.itemBoxCalendarLeftEnd-po {
    background-color: #6ec2e6;
    height: 1.4rem;
}

.itemBoxCalendarLeftEnd-po:before {
    position: absolute;
    left: -.5rem;
    top: 0;
    content: '';
    border-top: .7rem solid transparent;
    border-bottom: .7rem solid transparent;
    border-right: .7rem solid #6ec2e6;
    border-radius: 3px;
    height: 1.4rem;
}

.itemBoxCalendarRight-po {
    background-color: #6ec2e6;
    height: 1.4rem;
}

.itemBoxCalendarRight-po:after {
    position: absolute;
    left: calc(100% - .5rem);
    top: 0;
    content: '';
    border-top: .7rem solid #6ec2e6;
    border-bottom: .7rem solid #6ec2e6;
    border-left: 2rem solid #6ec2e6;
    border-radius: 3px;
    height: 1.4rem;
}

/**/
.itemBoxCalendar-exe {
    background-color: #52ab05;
    height: 1.4rem;
}

.itemBoxCalendarRightEnd-exe {
    background-color: #52ab05;
    height: 1.4rem;
}

.itemBoxCalendarRightEnd-exe:after {
    position: absolute;
    right: -.5rem;
    top: 0;
    content: '';
    border-top: .7rem solid transparent;
    border-bottom: .7rem solid transparent;
    border-left: .7rem solid #52ab05;
    border-radius: 3px;
    height: 1.4rem;
}

.itemBoxCalendarLeftEnd-exe {
    background-color: #52ab05;
    height: 1.4rem;
}

.itemBoxCalendarLeftEnd-exe:before {
    position: absolute;
    left: -.5rem;
    top: 0;
    content: '';
    border-top: .7rem solid transparent;
    border-bottom: .7rem solid transparent;
    border-right: .7rem solid #52ab05;
    border-radius: 3px;
    height: 1.4rem;
}

.itemBoxCalendarRight-exe {
    background-color: #52ab05;
    height: 1.4rem;
}

.itemBoxCalendarRight-exe:after {
    position: absolute;
    left: calc(100% - .5rem);
    top: 0;
    content: '';
    border-top: .7rem solid #52ab05;
    border-bottom: .7rem solid #52ab05;
    border-left: 2rem solid #52ab05;
    border-radius: 3px;
    height: 1.4rem;
}

/**/

.itemBoxCalendarEnpty {
    background-color: #dddddd;
    height: 1.4rem;
}

.itemBoxCalendarEnptyRight {
    background-color: #dddddd;
    height: 1.4rem;
}

.itemBoxCalendarEnptyRight:after {
    position: absolute;
    left: calc(100% - .5rem);
    top: 0;
    content: '';
    border-top: .72rem solid #dddddd;
    border-bottom: .7rem solid #dddddd;
    border-left: 2rem solid #dddddd;
    border-radius: 3px;
    height: 1.4rem;
}

.itemBoxCalendarEnptyRightEnd {
    background-color: #dddddd;
    height: 1.4rem;
}

.itemBoxCalendarEnptyRightEnd:after {
    position: absolute;
    right: -.5rem;
    top: 0;
    content: '';
    border-top: .7rem solid transparent;
    border-bottom: .7rem solid transparent;
    border-left: .7rem solid #dddddd;
    border-radius: 3px;
    height: 1.4rem;
}

.itemBoxCalendarEnptyLeftEnd {
    background-color: #dddddd;
    height: 1.4rem;
}

.itemBoxCalendarEnptyLeftEnd:before {
    position: absolute;
    left: -.5rem;
    top: 0;
    content: '';
    border-top: .7rem solid transparent;
    border-bottom: .7rem solid transparent;
    border-right: .7rem solid #dddddd;
    border-radius: 3px;
    height: 1.4rem;
}

.dayNumber-button {
    cursor: pointer;
    height: 1.5rem;
    width: 3rem;
    padding: 0 1rem;
    margin: auto;
    color: #000000a6;
    border-radius: 3px;
}

.dayNumber-button:hover {
    background-color: #dddddda6;
}
.gridContainer {
    grid-gap: 0.5rem;
    margin: 1rem 0;
    justify-content: center;
}

.modulesButton {
    display: flex;
    justify-content: flex-start;
    padding: 0 0.5rem;
    width: 10rem;
}

.modulesButton span:first-child {
    color: rgb(250, 125, 52);
}

.modulesButton span:nth-child(2) {
    font-size: 1rem;
}

.modulesContainer {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1rem;
}

.modulesTitle {
    text-align: start;
    color: rgb(250, 125, 52);
}

.noModulesTitle {
    text-align: center;
    color: white;
    padding: 4rem 0;
}

.pageTitleContainer {
    margin-bottom: 1rem;
}

.pageTitle {
    margin: 0.25em 0;
}

.titleListStations {
    margin: 1rem;
    white-space: nowrap;
    width: 13.75rem;
    color: rgb(250, 125, 52);
}

.bodyListStations {
    overflow: auto;
    height: 100%;
}

.containerListStations {
    max-height: 20rem;
}

.stationDataModal {
    max-height: 36rem;
    width: 60rem;
    padding: 0;
}

.modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    background-color: #2D3238;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
}

.leftDivHeader {
    width: 33%;
    text-align: left;
}

.centerDivHeader {
    width: 33%;
    text-align: center;
}

.rightDivHeader {
    width: 33%;
    text-align: right;
}

.leftDivBody {
    width: 10%;
    text-align: left;
}

.centerDivBody {
    width: 80%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightDivBody {
    width: 10%;
    text-align: right;
}

.titleStationDataModal {
    color: white;
    margin: 0;
}

.configurationDataContainer {
    display: flex;
    flex-direction: column;
}

.selectStationContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
}

.selectStation {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 1px solid black;
    cursor: pointer;
    width: 12.5rem;
    align-self: flex-end;
    padding: 0.5rem 0;
}

.selectStation:hover {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 2px solid black;
    cursor: pointer;
}

.selectModulesContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    font-size: larger;
    overflow: auto;

    @media screen and (max-width: 1600px) {
        margin: 0 1rem;
    }
}

.selectModules {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 1px solid black;
    cursor: pointer;
    padding: 0.5rem 0;
    font-size: larger;
}

.selectModules:hover {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 2px solid black;
    cursor: pointer;
}

.selectModulesDisabled {
    background: transparent;
    text-align: center;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding: 0.5rem 0;
    font-size: larger;
}

.dateContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 0.5rem 0;
}

.dateButton {
    height: 24px;
    background: rgb(250, 125, 52);
    width: 68px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    line-height: 0;
}

.dateButtonDisabled {
    margin: 0.5rem;
    background-color: lightgray;
    border: none;
    padding: 0 0.5rem;
    font-weight: 400;
    color: white;
    border-radius: 0.5rem;
    font-size: medium;
}

.locationContainer {
    padding: 0.5rem 0;
}

.chartContainer {
    margin: 1rem;
}

.restartFilterButton {
    padding: 0.5rem;
    border: none;
    font-weight: 400;
    color: white;
    border-radius: 0.5rem;
    font-size: medium;
    cursor: pointer;
}

.inputDate input:first-child {
    text-align: center;
}

.modulesIcon {
    height: 2.5rem;
    width: 2.5rem;
}

.textModalStation input:first-child {
    text-align: center;
    padding-top: 0.75rem;
}

.dynamicWeatherInformationContainer {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
}

.weatherInformationContainer {
    margin: 1rem;
    display: flex;
    flex-direction: column;
}

.weatherInformationLabel {
    text-align: start;
    font-size: 0.875rem;
}

.weatherInformationInput {
    border: none;
    border-bottom: 1px solid gray;
    text-align: center;
    font-size: 1rem;
    width: 24rem;
}

.weatherCard {
    background-color: rgb(52, 58, 64);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 9rem;
    width: 12.75rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
}

.weatherData {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 8rem;
    width: 11.75rem;
    margin: 0.5rem 0;
    border-right: 1px solid gray;
}

.weatherInformationInput:hover {
    border-bottom: 2px solid black;
}

.weatherListData,
.inputWeatherForecast {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

input:checked~.weatherListData {
    max-height: 210rem;
}

.weatherListItem {
    list-style: none;
    margin: 0.5rem 0;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
}

.weatherListContainer {
    max-height: 20rem;
}

.forecastInformation {
    list-style: none;
    margin: 0.5rem 0;
    color: white;
}

.labelWeatherForecast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 0 0.5rem;
}

.titleHistoric {
    padding: 0.25rem 1rem 0 1rem;
    margin: 0;
    white-space: nowrap;
    color: white;
    font-size: 1.5rem;
}

.titleWeatherForecast {
    padding: 0 1rem 0 1rem;
    margin: 0;
    white-space: nowrap;
    width: 100%;
    color: white;
    font-size: 1.5rem;
}

.arrowIcon {
    display: inline-block;
    color: rgb(250, 125, 52);
}

.inputWeatherForecast+.labelWeatherForecast .arrowIcon {
    display: inline-block;
    transition: all 0.6s;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.inputWeatherForecast:checked+.labelWeatherForecast .arrowIcon {
    display: inline-block;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.windDirectionIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
}

.circle {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    border: 2px dotted white;
    border-radius: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.windDirection {
    position: absolute;
    font-size: 0.8rem;
}

.north {
    top: -2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.south {
    bottom: -2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.east {
    right: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.west {
    left: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.windDirectionArrow {
    margin-top: -3rem;
    margin-bottom: 1rem;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.listItem {
    border-left: 0.5rem solid white !important;
    background-color: rgb(250, 125, 52) !important;
    color: white !important;
}

.zoom {
    position: absolute;
    z-index: 401;
    width: 3.7rem;
    padding: 0.25rem;
    top: 0;
}

.containerToLocate {
    padding: 1rem;
    z-index: 997;
    left: 4rem;
    box-shadow: 2px 2px 5px gray;
    top: 4.75rem;
    position: absolute;
    text-align: center;
}

.containerChangeStation {
    padding: 1rem;
    z-index: 997;
    left: 23rem;
    box-shadow: 2px 2px 5px gray;
    top: 4.75rem;
    position: absolute;
    text-align: center;
}

.historyCardContainer {
    display: flex;
    justify-content: space-around;
    overflow-x: auto;
    white-space: nowrap;
}

.historyCard {
    display: inline-block;
    margin: 0 0.1rem;
    background: rgb(32, 35, 39);
    color: white;
    border-radius: 0.5rem;
    text-align: center;
    cursor: pointer;
    width: 4rem;
    border: none;
}
.anime-close {
    height: 0 !important;
}

.anime {
    /* For Safari 3.1 to 6.0 */
    transition: all .2s ease-in-out;
    overflow: hidden !important;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;    
}

.ini {
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.back {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: .3;
    z-index: 1002;
}

.sr-only {
  display: none;
}

.header {
  z-index: 600;
  position: fixed;
  width: 100%;
  margin-left: 15rem;
  height: 4rem;
  background-color: white;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.sidenav {
  position: fixed;
  width: 15rem;
  height: 100%;
  background-color: #343a40;
  z-index: 1003;
}

.sidenav-layout {
  display: grid;
  grid-template-rows: 4rem auto 10rem;
}
.sidenav-header {
  line-height: 4rem;
  padding-left: 1.5rem;
  height: 4rem;
  box-shadow: -10px 3px 5px 0px rgba(0, 0, 0, 0.2), -10px 8px 10px 0px rgba(0, 0, 0, 0.14), -10px 3px 14px 0px rgba(0, 0, 0, 0.12);

  padding: .5rem;
  padding-left: 3rem;
}
.sidenav-header a {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    margin-left: 3rem;
}
.sidenav-footer {

  background-color: rgba(0, 0, 0, 0.01);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.menu {
  overflow-y: auto;
  position: relative;
  height: 100%;
  margin-bottom: -7.75rem; /* For Safari 3.1 to 6.0 */
  transition: all .2s linear;  
}
.menu::-webkit-scrollbar {
  width: 4px;
}
.menu::-webkit-scrollbar-track {
  background: rgba(110, 107, 107, 0.541); 
}
.menu::-webkit-scrollbar-thumb {
  background: rgba(179, 179, 179, 0.568); 
}
.menu::-webkit-scrollbar-thumb:hover {
  background: rgba(179, 179, 179, 0.692); 
}

.nav-text {
    color: #fff;
    line-height: 44px;
    opacity: .85;
    font-size: 14px;
}

.iconS {   
    width: 1.5rem !important;
}
.iconM {
  padding-left: .5rem;
  line-height: 4rem;
  color: black;
  width: 2rem !important;
  height: 2rem !important;
}
.menu_icon {
  padding-left: .5rem;
  line-height: 3rem;
  color: black;
  width: 2rem !important;
  height: 3rem !important;
}
.iconMenu {
  font-size: 16px !important;
  width: 16px;
  margin-right: 8px;
}
.wrapper {
  overflow: auto;
  min-height: 99%;
  margin-bottom: -2.75rem;
  margin-left: 15rem;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 4rem auto 1.25rem;
}

.wrapperMap {
  position: relative;
}

.push {
  /*height: 4rem;*/
  height: 100%;
}

.push-bottom {
  /*height: 1.25rem;*/
  height: 100%;
}

.footer {  
  margin-left: 15rem;
  margin-bottom: 0rem;
  text-align: right;
  padding: 11px 30px 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.01);
}
.footer-text span {
  justify-content: space-between;    
  font-size: 11.2px;
  
  opacity: .87;
  color: rgba(0, 0, 0, 0.87);
}


.header-container {
  display: flex;
  flex-direction: row;
  margin-right: 15rem;
}

.left-header {
  padding-left: 1rem;
}

.icon-pos-top {
  margin-top: .5rem;
}

.icon-pos_sub {
  position: relative;
  height: 100% !important;
}

.header-center {
  width:100%;
}

.header-right {
  line-height: 4rem;
  text-align: right;
  padding-right: 1rem;
  display: inline-flex;
}
.div-list_a{ 
  position: relative;
  overflow-y: auto;
  height: auto;
  width: 15rem;
}
.div-list_h{ 
  position: relative;
  height: calc(100vh - 20rem);
  width: 25rem;
}
.div-list_itens{ 
  height: calc(100vh - 21rem);
  width: 23.5rem;
}
.div-list_box{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 20rem);
  width: 24.8rem;
}
.div-list_itens_list{
  height: calc(100vh - 20rem);
  width: 24.5rem;
}

.list-p{
  display: grid;
  height: 100%;
  grid-template-rows: 4rem auto;
}
.list-p-filter{
  display: grid;
  height: 100%;
}
.list-pT{
  display: grid;
  height: 100%;
  grid-template-rows: 4rem calc(100% - 11rem);
}
.map-p{
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;  
}

/*//////////////////////////////////////*/

.ant-menu-item-selected {
  background-color: transparent !important;
  border: 0 !important;
  padding: 0;
  margin: 0;
}
.ant-menu-item, .ant-menu-submenu-title {
  padding: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;    
}
.nav-menu,.ant-menu-dark {
  background: #343a40 !important;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1rem !important;
}

.nav-menu2 {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1rem !important;
}

.ant-menu-sub {    
  background-color: #2d3238 !important;
}
ul .nav-item {
  padding-left: 2rem;
  color: rgba(255, 255, 255, 0.65) !important;
  
}
ul .nav-item-sub {
  padding-left: 3rem;
}

button.nav-item,
a.nav-item {
  text-decoration: none;
  text-transform: none;
  width: 100%;
  height: 100%;
  justify-content: left;
}

.MuiButton-label span {
  position: absolute;
  left: 4rem;
  top: .5rem;
}

.header, .header-container, .footer, .wrapper, .sidenav {
  transition: all .3s;
}

.sidenavShow {
  -webkit-transform: translateX(0rem) !important;
          transform: translateX(0rem) !important;
  
}

a.list-inline-item {
 color: rgba(0, 0, 0, 0.65);
}
.user-top {
  right: 1rem;
  text-align: center;
  
}

a.list-inline-item:hover > div {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f7f7f7 !important;
}

/*************/
.popup-list {
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
}

.popup-item {
  font-size: .875rem !important;
  height: auto !important;
  text-align: center !important;
  padding: .5rem !important;
  color: rgba(0,0,0,.87) !important;
}

.divider {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: rgba(0,0,0,.1);
}

.linha-pop {
  color: rgba(0,0,0,.87) !important;
  width: 5rem;
  height: 2rem !important;
}

a.linha-pop:hover {
  color: rgba(0,0,0,.87);
}
.icon-fix {
  font-size: 1rem !important;
}

.div-list-in {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 15rem;
  overflow-y: auto;
}

.modalPanel {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  height: 100%;
  margin-top: -1rem;
}

@media screen and (max-width: 1024px) {
  .header, .header-container, .footer, .wrapper {
      margin-left: 0;
      margin-right: 0;
  }
  .sidenav {
      -webkit-transform: translateX(-15rem);
              transform: translateX(-15rem);
  }
  .hideS {
      display: block;
  }
}
@media screen and (min-width: 1025px) {
  .back {
      display: none;
  }

  .hideS {
      display: none;
  }
  
}
@media screen and (max-width: 870px) {
  .map-p{
    grid-template-rows: -webkit-min-content auto;
    grid-template-rows: min-content auto;
  }  

  .div-list_box{
    height: calc(100vh - 24rem);
  }
  .div-list_itens{
    height: calc(100vh - 22rem);
  }
  .div-list_h{ 
    height: calc(100vh - 22rem);
  }
}
.lay_top {
  position: fixed;
  top: 5.5rem;
  left: 17rem;
  width: calc(100% - 19rem);
  z-index: 12;
}

.lay_side {
  margin-top: 11.3rem;
  width: 25rem;
  height: 47rem;
}

.lay_side_top_d0 {
  position: fixed;
  top: 12.5rem;
  left: 17rem;
  width: 25rem;
  height: 5rem !important;
  padding: .5rem;
  height: 4rem;
  overflow-y: hidden;
  z-index: 10
}

.lay_side_top_d1 {
  position: fixed;
  top: 0;
  left: 16.5rem;
  width: 26rem;
  height: 16.3rem;
  background-color: #f5f5f5;
  z-index: 9
}

.lay_side_top_d2 {
  position: fixed;
  top: 17.1rem;
  left: 17rem;
  width: 25rem;
  height: .5rem;
  background-color: white;
  z-index: 12;
}

.lay_map {
  position: fixed;
  top: 12.5rem;
  left: 43rem;
  width: calc(100% - 45rem);
  height: calc(100% - 15.8rem);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* width */
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(197, 197, 197);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(216, 216, 216);
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #6c76ff !important;
}

.input-group-v1 label {
  left: 36px;
  transition: all .2s cubic-bezier(0, 0, .2, 1);
}

.LocalTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.LocalTab-root {
  text-transform: none;
  padding: 6px 12px;
  overflow: hidden;
  position: relative;
  font-size: 0.8125rem;
  max-width: 264px;
  box-sizing: border-box;
  min-height: 48px;
  text-align: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.02857em;
}

.form-group {
  position: relative;
  text-align: center;
  margin-top: 1rem;
  padding: 1rem
}

.input-group-v1 .input-group-icon {
  position: absolute;
  left: 0;
  bottom: 4px;
  font-size: 24px;
}

.form-v1 .form-group {
  padding: 0;
}

.input-group-v1 input {
  padding-left: 36px;
}

.popUpButton .MuiButton-label {
  position: relative;
}

.blButton {
  min-width: 3rem !important;
}

.cicloButton {
  margin: 0 0 0 .5rem !important;

}

.back-drop {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
}

.in-back-drop {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.back-quest {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-select-f {
  width: 34rem;
  max-width: 100%;
  display: grid;
  grid-template-columns: 15rem 4rem 15rem;
  height: calc(100% - 5rem);
}

/*

.back-quest {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.div-select-f {
  width: 34rem;
  max-width: 100%;
  position: absolute;
  margin-left: -17rem;
  left: 50%;
  display: grid;
  grid-template-columns: 15rem 4rem 15rem;
  height: calc(100% - 2rem);
}

*/

.div-select {
  background-color: black;
  opacity: .3;
  width: 100%;
  height: 100%;
  position: absolute;
}

.page-filter {
  display: grid;
  height: 100%;
  grid-template-columns: 2.5rem auto 2.5rem;
}

.MuiTableCell-sizeSmall {
  padding: 6px 16px 6px 16px !important;
}

/*****************************************************/
.MuiSlider-markLabel {
  font-size: 0.75rem !important;
}

/*.leaflet-container {
  height: 38rem;
  min-width: 20rem;
  border: 1px solid rgb(233, 233, 233);
}*/

.leaflet-edit-marker-selected {
  border: 4px dashed orange !important;
}

.sr-only {
  display: none;
}

.checkMap {
  top: -9px !important;
}

.checkMap:hover {
  background-color: transparent !important;
}

.leaflet-div-icon {
  border: 2px solid #666 !important;
  border-radius: 35px !important;
  height: 15px !important;
  width: 15px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.Edit-textField-46 {
  margin-left: 0 !important;
}

.tool-tip-size24-L {
  font-size: 24px;
}

.tool-tip-size16-L {
  font-size: 16px;
}

.tool-tip-size10-L {
  font-size: 10px;
}

.tool-tip-L {
  background: transparent;
  border: none;
  box-shadow: none;
  font-weight: bold;
  font-family: "Roboto";
  text-shadow:
    -1px -1px 1px rgb(255, 255, 255, .25),
    1px -1px 1px rgb(255, 255, 255, .25),
    -1px 1px 1px rgb(255, 255, 255, .25),
    1px 1px 1px rgb(255, 255, 255, .25),
    1px 0px 1px rgb(255, 255, 255, .25),
    -1px 0px 1px rgb(255, 255, 255, .25),
    0px 1px 1px rgb(255, 255, 255, .25),
    0px -1px 1px rgb(255, 255, 255, .25);
}

.tool-tip-H {
  font-size: 15px;
  background: transparent;
  border: none;
  box-shadow: none;
  font-weight: bold;
  font-family: "Roboto";
  text-shadow:
    -1px -1px 1px rgb(0, 0, 0, .25),
    1px -1px 1px rgb(0, 0, 0, .25),
    -1px 1px 1px rgb(0, 0, 0, .25),
    1px 1px 1px rgb(0, 0, 0, .25),
    1px 0px 1px rgb(0, 0, 0, .25),
    -1px 0px 1px rgb(0, 0, 0, .25),
    0px 1px 1px rgb(0, 0, 0, .25),
    0px -1px 1px rgb(0, 0, 0, .25);
}


.popUpF {
  max-height: 100%;
  display: grid;
  grid-template-rows: minmax(4rem, 100%) 1.5rem;
}

.bLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.bLineO {
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.titleTextRel {
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: .75;
  letter-spacing: 0.00938em;
}

.contentTextRel {
  display: inline-flex;
  height: 2.4rem;
  font: inherit;
  width: 100%;
  padding: 6px 0 7px;
}

.contentValueRel {
  font-size: 13px;
  display: flex;
  align-items: center;
  width: 8rem;
}

.contentPercRel {
  font-size: 13px;
  display: flex;
  align-items: center;
  width: 3.6rem;
}

.spaceListRight {
  width: 3rem;
  text-align: right;
}

.spaceListLeft {
  width: 3rem;
  text-align: left;
}

.menuBot {
  margin-bottom: -14rem;
  /* For Safari 3.1 to 6.0 */
  transition: all .1s linear;
}

.outThere {
  margin-top: 0 !important;
}

.fixedTotal {
  position: fixed;
  top: 64px;
  left: 256px;
  /* For Safari 3.1 to 6.0 */
  transition: all .2s linear;
}

.scrollItens {
  margin-left: 25rem;
  height: 100%;
  overflow-y: auto;
}

.autoDiv {
  overflow-y: auto;
  max-height: 20rem;
  min-height: 1rem;
  width: 100%;
  background-color: white;
  position: absolute;
  z-index: 999;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
}

.popUpInput {
  width: 4.5rem;
  border: none;
  border-bottom: solid 1px #aaa;

}

.popUpInput:focus {
  border: none;
  border-bottom: solid 1px #aaa;
  outline: #aaa;
  -webkit-appearance: none;
}

.popUpInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tableList {
  background-color: white;
}

.active,
.tableList:hover,
.accordion:hover {
  background-color: #f1f1f1 !important;
}

.panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.textButtom {
  margin: 0 0.2rem 0.5rem 0.2rem !important;
  font-size: .8rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  color: white !important;
}

.MuiDialog-paperFullWidth {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: 0 0 0 0 !important;
}

@media screen and (max-width: 1024px) {
  .fixedTotal {
    left: 1rem;
  }
}

@media screen and (max-width: 785px) {
  .fixedTotal {
    position: relative;
    margin-top: -4rem;
    margin-bottom: 4rem;
  }

  .scrollItens {
    margin-left: 1rem;
  }
}

.nav-item:disabled span {
  color: #7D7D7D;
}

.MuiInputLabel-shrink {
  -webkit-transform: scale(1) !important;
          transform: scale(1) !important;
  font-size: 12px !important;
}

.dashboard-card {
  position: relative;
  min-width: 16rem;
  min-height: 12rem;
  height: 100%;
  transition: box-shadow 0.3s ease;
}

.dashboard-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dashboard-card {
  grid-gap: 1rem;
  gap: 1rem;
  border-radius: .8rem;
  background-color: #343a40;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
}

.dashboard-card .cardheader {
  text-align: left;
  color: white;
  display: flex;
  padding: 0.5rem;
}

.dashboard-card .cardheader .title {
  height: 1.7rem;
  line-height: 1.7rem;
  vertical-align: super;
  padding-left: .3rem;
}

.dashboard-card .cardmiddle {
  display: flex;
  grid-gap: .5rem;
  gap: .5rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.dashboard-card .cardmiddle .cardmiddle-legend {
  width: 100%;
  position: absolute;
  color: #fa7d34;
}

.dashboard-card .cardmiddle .cardmiddle-value {
  color: #fa7d34;
  font-size: 3.5rem;
  -webkit-margin-after: auto;
          margin-block-end: auto;
}

.dashboard-card .cardfooter {
  display: flex;
  justify-content: center;
  margin: -1rem;
  -webkit-border-before: solid .2rem #343a40;
          border-block-start: solid .2rem #343a40;
  position: absolute;
  width: 100%;
  bottom: 1rem;
}

.dashboard-card .cardfooter .cardfooter-content {
  background-color: #343a40;
  color: #FFF;
  width: 100%;
  height: 3rem;
  font-size: .7rem;
  padding-top: .3rem;
  text-align: center;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se:after {
  border-color: white;
  border-right: 0.25rem solid white;
  border-bottom: 0.25rem solid white;
  width: 0.75rem;
  height: 0.75rem;
  bottom: 0.25rem;
  right: 0.25rem;
}

.react-grid-item.react-grid-placeholder {
  background-color: #fa7d34;
  border-radius: .8rem;
  opacity: 0.5;
}
/*@import url("//unpkg.com/maplibre-gl@1.14.0/dist/maplibre-gl.css");*/

/*login*/
.login-page {
  height: 100vh;
  width: auto;
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
}

.login-img {
  background-image: url('/assets/covers/4.jpg');
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.login-div {
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  min-width: 18rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  max-width: 20rem;
  min-width: 18rem;
}

.login-container {
  margin-left: auto;
  margin-right: auto;
}

.login-div h2 {
  font-size: 2.125rem;
  margin-bottom: 1.5rem;

  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}

.login-div .lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.login-div h2,
.login-div .lead {
  margin-bottom: 1.5rem;
  text-align: center;
}

.login-div a {
  text-align: center;
}

.login-button {
  height: 2rem;
  width: 100%;
  text-align: center;
}

.bitMoreSpace {
  position: relative;
  margin-top: 1rem;
}

/*******/
.rowMap {
  position: relative;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  grid-template-columns: 15rem auto;
  grid-gap: 1rem;
}

.rowMapC {
  position: relative;
  display: grid;
  grid-template-columns: 15rem auto;
  grid-gap: 1rem;
}

.rowMapCO {
  position: relative;
  display: grid;
  grid-template-columns: 25rem auto;
  grid-gap: 1rem;
}

.rowForm {
  padding: 2rem;
  min-height: calc(100vh - 5.2rem);
}

.divider {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.divContent {
  padding: 1rem;
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
}

.divContentList {
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
}

.divContentItem {
  margin: 1rem;
  padding: 1rem;
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.cursorP {
  cursor: pointer;
  z-index: 5000 !important;
}

.resumos {
  display: flex;
  flex-wrap: wrap;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.resumoTracker {
  margin-bottom: 15px;
  padding: 15px 15px 20px 15px;
  width: 350px;
  height: 310px;
  margin-right: 10px;
}

.resumoTracker h1 {
  padding-left: 5px;
  text-align: center;
  background: rgb(255, 133, 27);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
}

.tabelaResumo {
  display: grid;
}

.tempoResumo {
  display: inline-block;
  border-bottom: 1px solid #aaa;
}

.tempoResumo span {
  float: right;
  margin-right: 2px;
}

.leaflet-container {
  height: 100%;
  min-width: 20rem;
  border: 1px solid rgb(233, 233, 233);
}

.leaflet-edit-marker-selected {
  border: 4px dashed orange !important;
}

.sr-only {
  display: none;
}

.gridDoble {
  grid-column: 1 / 3;
}

.leaflet-div-icon {
  border: 2px solid #666 !important;
  border-radius: 35px !important;
  height: 15px !important;
  width: 15px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-bandana {
  background-color: orange;
  border: 4px solid rgb(237, 231, 231) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-liga {
  background-color: DarkCyan;
  border: 4px solid rgb(7, 151, 26) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-desliga {
  background-color: DarkCyan;
  border: 4px solid rgb(228, 34, 34) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-voo {
  background-color: DarkCyan;
  border: 4px solid rgb(28, 24, 236) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-init {
  background-color: DarkCyan;
  border: 4px solid rgb(226, 223, 17) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.leaflet-div-icon-stop {
  background-color: orange;
  border: 4px solid rgb(204, 107, 17) !important;
  border-radius: 45px !important;
  height: 20px !important;
  width: 20px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.containerTitle {
  /*height: 3rem;*/
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.overall {
  z-index: 999;
}

.date-pick {
  margin-right: 50px;
  margin-left: 10px;
}

.containerTop {
  padding-bottom: .5rem;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.leftTop {
  padding-left: 1rem;
  padding-top: .3rem;
  flex: 1 1 1;
}

.centerTop {
  flex: 1 1 auto;
}

.rightTop {
  text-align: right;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: .3rem;
  flex: 1 1 auto;
}


.buttonS {
  padding: 0rem;
}

.buttonMenu {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  background-color: white !important;
}

.buttonMenuWeather {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  background-color: rgb(250, 125, 52) !important;
}

.buttonMenuHL {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  background-color: #95f098 !important;
}

.buttonMenuAL {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  background-color: #ffd754 !important;
}

.buttonMenuAF {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  color: white !important;
  background-color: #e0bc07 !important;
}

.buttonMenuOP {
  font-size: 1.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 1rem !important;
  min-height: 1rem !important;
  width: 2.5rem !important;
  height: 2.3rem !important;
  color: white !important;
  background-color: #3a8bc9 !important;
}

.buttonMenuPS {
  width: 1.2rem !important;
  margin-right: .2rem !important;
}


.buttonMenuS {
  margin-left: 3rem;
}

.buttonMenuSmall {
  display: none !important;
}

.zoomB {
  position: absolute;
  z-index: 401;
  width: 3.7rem;
  padding: 1rem;
  top: 0;
}

.mapS {
  padding-top: 2rem;
  padding-bottom: .5rem;
}

.iconMap {
  line-height: 2rem;
  width: 2rem !important;
  height: 2rem !important;
}

.iconMapF {
  line-height: 1rem;
  margin-left: -.3rem;
  margin-top: 0rem;
  color: black;
  width: 2rem !important;
  height: 2rem !important;
}

.iconMapFO {
  line-height: 2rem;
  margin-left: -0.3rem;
  margin-top: 0rem;
  color: black;
  width: 2rem !important;
  height: 2rem !important;
}

.iconMapP {
  margin-top: .2rem;
  line-height: 1.8rem;
  width: 1.3rem !important;
  height: 1.3rem !important;
  color: black;
}

.iconArrowUp {
  margin-top: .3rem;
  line-height: 1.8rem;
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.iconMapS {
  line-height: 2rem;
  width: 2rem !important;
  height: 2rem !important;
  margin-top: -.4rem;
}

.div-list-track {
  height: calc(100% - 17rem);
}

.div-list-track-sumary {
  height: calc(100% - 6rem);
}

.div-listFloat {
  margin-left: 18.5rem;
}

.div-listFloatC {
  margin-left: 17.5rem;
}

.floatW {
  position: absolute;
  top: 1rem;
  text-align: center;
}

.divS {
  padding: 1rem;
  z-index: 997;
  right: 1rem;
  box-shadow: 2px 2px 5px gray;
}

.floatDiv {
  position: absolute;
  top: 1rem;
  margin-left: 4.5rem;
  /* margin-left: -6.5rem; */
  /* left: 50%; */
  z-index: 500;
  text-align: center;
}

.divRelative {
  position: relative;
}

.floatDivItens {
  width: 17.5rem;
  z-index: 1002;
}

.divMP {
  width: 24rem;
  padding: 1rem;
  z-index: 501;
  right: 5rem;
  box-shadow: 2px 2px 5px gray;
}

.divI {
  left: 14rem;
  margin-left: -8rem;
  width: 24rem;
  padding: 1rem;
  z-index: 502;
  box-shadow: 2px 2px 5px gray;
}

.divIA {
  left: 14rem;
  margin-left: -8rem;
  width: 15rem;
  padding: 1rem;
  z-index: 502;
  box-shadow: 2px 2px 5px gray;
}

.formItens {
  display: grid;
  justify-items: center;
  grid-template-columns: auto auto;
}

.divForm {
  margin-top: .2rem;
}

.textI {
  width: 8rem;
  margin-left: .5rem;
}

.SelItem {
  border-left: 6px solid #666 !important;
  background-color: #f0f0f0 !important;
}

.SelItemFull {
  border-left: 6px solid #666 !important;
  border-right: 6px solid #666 !important;
  background-color: #f0f0f0 !important;
}

.notTooBig {
  width: 100%;
  max-width: 40rem;
}

.notTooBig2 {
  width: 100%;
  max-width: 50rem;
}

.notTooClose {
  margin-right: 8px !important;
  width: 11rem;
}

.rel {
  padding: 1rem !important;
  padding-bottom: .2rem !important;
}

.rel-top {
  padding: 1rem !important;
  padding-bottom: .2rem !important;
}

.maxH {
  min-height: calc(100vh - 9.4rem);
  width: 100%;
}

.maxHT {
  min-height: calc(100vh - 14.8rem);
  width: 100%;
}

.maxHG {
  min-height: calc(100vh - 15.8rem);
  width: 100%;
}

.maxVH {
  height: calc(100vh - 15.8rem);
  max-height: calc(100vh - 15.8rem);
  width: 100%;
}

.gridEst {
  display: grid;
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  grid-gap: 1rem;
  gap: 1rem;
}

.backStage {
  position: absolute;
  background-color: #f5f5f5;
}

.gridFiltro {
  display: grid;
  grid-template-rows: .5rem 0.3rem;
  grid-template-columns: 11rem 0rem;
}

.login-logo {
  background-image: url('/assets/covers/logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 150px;
  flex: 0 0 100%;
  max-width: 100%;
}

.menu-logo {
  background-image: url('/assets/covers/logo_header.png');
  background-size: 125px 48px;
  width: 125px;
  height: 48px;
}

.div-listFloatP {
  margin-left: 0;
}

@media screen and (max-width: 870px) {
  .rowMap {
    padding: 2rem;
    display: grid;
    grid-template-columns: auto;
  }

  .rowMapC {
    display: grid;
    grid-template-columns: auto;
  }

  .rowMapCO {
    display: block;
  }

  .div-list {
    z-index: 1001;
    position: absolute;
    width: 15rem;
    left: -17.5rem;
    top: 8.1rem;
    height: 75%;
    box-shadow: 2px 2px 5px gray;
    /* For Safari 3.1 to 6.0 */
    transition: all .2s linear;
  }

  .div-list_p {
    z-index: 1001;
    position: absolute;
    width: 25rem;
    left: -28rem;
    top: 4.1rem;
    height: calc(100% - 4rem);
    box-shadow: 2px 2px 5px gray;
    /* For Safari 3.1 to 6.0 */
    transition: all .2s linear;
  }

  .div-listFloatP {
    margin-left: 28.5rem;
  }

  .leftTop {
    padding-left: 0rem;
  }

  .rightTop {
    padding-right: 0rem;
  }

  .buttonMenuSmall {
    margin-right: .3rem !important;
    display: inline !important;
  }

  .gridEst {
    grid-template-rows: -webkit-min-content calc(100vh - 16rem);
    grid-template-rows: min-content calc(100vh - 16rem);
  }
}

@media screen and (min-width: 871px) {
  .div-listFloat {
    margin-left: 0;
  }

  .div-listFloatC,
  .div-listFloatJ {
    margin-top: 1rem;
    margin-left: 0;
  }

  .list-margin {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .rowMap {
    padding-right: .4rem;
    padding-left: .4rem;
  }

  .rowForm {
    padding: .4rem;
  }

  .gridEst {
    grid-template-rows: -webkit-min-content auto;
    grid-template-rows: min-content auto;
    grid-gap: 0.4rem;
    gap: 0.4rem;
  }

  .maxH {
    min-height: calc(100vh - 5rem);
  }

  .maxVH {
    height: calc(100vh - 12.8rem);
    max-height: calc(100vh - 12.8rem);
  }
}

@media screen and (max-width: 960px) {

  .login-img,
  .login-logo {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .login-div {
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    flex: 0 0 100%;
  }
}

.monitoringMarkerIconBlue {
  background-color: rgb(0, 162, 255) !important;
  border: solid 2px rgb(255, 255, 255) !important;
  border-radius: 35px !important;
  height: 15px !important;
  width: 15px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
}

.monitoringLineBlue {
  stroke: rgb(0, 162, 255);
}

.leaflet-div-icon2 {
  background: none !important;
}

.leaflet-hide-icon2 {
  border: none !important;
}
